import { parseDateTime, toZoned } from "@internationalized/date";

export function formatDate(dateString?: string | null, options?: {
    timeZone?: string
    timeStyle?: "full" | "long" | "medium" | "short"
    dateStyle?: "full" | "long" | "medium" | "short"
}) {
    if (!dateString) {
        return null;
    }

    options = options ?? {};

    if (options.timeStyle === undefined) {
        options.timeStyle = 'long';
    }

    if (options.dateStyle === undefined) {
        options.dateStyle = 'short';
    }

    const dateObj = new Date(dateString);

    try {
        return new Intl.DateTimeFormat(undefined, options).format(dateObj);
    } catch (e) {
        console.error('Error in formatting date', e);
        return null;
    }
}

export function formatTimezone(timezone: string) {
    try {
        const intlObject = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            timeZoneName: 'short',
        });

        const now = new Date();
        const parts = intlObject.formatToParts(now);

        const timeZonePart = parts.find(part => part.type === 'timeZoneName');
        const timeZoneResolved = intlObject.resolvedOptions().timeZone;

        if (timeZoneResolved === timeZonePart?.value) {
            return timeZoneResolved;
        }


        return `${timeZoneResolved} ${timeZonePart?.value}`;
    } catch (e) {
        console.error('Error in formatting timezone', e);
        return null;
    }
}

/**
 * Преобразует дату и время из input datetime-local в UTC ISO строку
 * с учетом выбранной таймзоны
 *
 * @param dateTimeLocalValue - значение из input datetime-local (например, "2025-03-07T19:00")
 * @param selectedTimezone - выбранная таймзона (например, "Europe/Kiev")
 * @returns ISO строка в UTC формате
 */
export function convertDateTimeLocalToUTC(
  dateTimeLocalValue: string,
  selectedTimezone: string,
): string {
  // Значение из input приходит без указания таймзоны
  // Формат: YYYY-MM-DDThh:mm (например, 2025-03-07T19:00)

  // Добавляем секунды, если их нет в исходной строке
  const normalizedValue =
    dateTimeLocalValue.includes(":") &&
    dateTimeLocalValue.split(":").length === 2
      ? `${dateTimeLocalValue}:00`
      : dateTimeLocalValue;

  // Парсим локальное время (считаем, что пользователь вводит время в выбранной таймзоне)
  const parsedDateTime = parseDateTime(normalizedValue);

  // Создаем объект даты с указанным часовым поясом
  const zonedDateTime = toZoned(parsedDateTime, selectedTimezone);

  // Получаем эквивалентное время в UTC
  return zonedDateTime.toAbsoluteString();
}